



$('#irUpload').click(function () {
    $('#passoConta10').hide();
    $('#passoConta10-1').show();
    $('#EnviarPorEmail').attr('checked', false);
    $('#nextController').val('CadastroFinalizado');
});
$('#irEmail').click(function () {
    $('#passoConta10').hide();
    $('#passoConta10-2').show();
    $('#nextController').val('CadastroFinalizado/Email');
});
$('#irSuporte').click(function () {
    $('#passoConta10').hide();
    $('#passoConta10-3').show();
    $('#EnviarPorEmail').attr('checked', false);
    $('#nextController').val('CadastroFinalizado');
});
$('.btnVoltarOpcoes').click(function () {
    $('#passoConta10-1, #passoConta10-2, #passoConta10-3').hide();
    $('#passoConta10').show();
    $('#nextController').val('CadastroFinalizado');
});

//Alterna Accordion
$('.envio-docs h3').click(function () {
    $('.envio-docs .content-doc').removeClass('open');
    $('.envio-docs h3 span').html('+');
    $(this).children('span').html('-');
    $(this).parent('.envio-docs').children('.content-doc').addClass('open');
    docSelecionado = $(this).parent('.envio-docs').children('.content-doc').children('a').attr('data-tipo');
    if (docSelecionado == "RG") {
    } else {
        geraCampoUploadDoc(docSelecionado);
    }
})

//Escolha de RG com ou sem nº de CPF
$('#btnRGsemCPF').click(function () {
    $('.envio-docs #rg').show();
    $('.envio-docs #rgcpf').hide();
    docSelecionado = $(this).attr('data-tipo');
    geraCampoUploadDoc(docSelecionado);
    $(this).parent('.escolha').children('.opcao').removeClass('active');
    $(this).addClass('active');
})
$('#btnRGcomCPF').click(function () {
    $('.envio-docs #rg').hide();
    $('.envio-docs #rgcpf').show();
    docSelecionado = $(this).attr('data-tipo');
    geraCampoUploadDoc(docSelecionado);
    $(this).parent('.escolha').children('.opcao').removeClass('active');
    $(this).addClass('active');
})

 //Se usuário selecionar enviar ficha por e-mail, some campo selecionar arquio para este item
$('#fichaPorEmail').change(function () {
    if ($(this).is(":checked")) {
        $('#accordionCONTRATO .btnCONTRATO, .pref-fpe').hide();
        $('.msg-sucesso-fpe').show();
        $('#nextController').val('CadastroFinalizado/Parcial');
    } else {
        $('#accordionCONTRATO .btnCONTRATO, .pref-fpe').show();
        $('.msg-sucesso-fpe').hide();
        $('#nextController').val('CadastroFinalizado');
    }
    $('#textbox1').val($(this).is(':checked'));
});

// Abre e Fecha PPE
$('#btnDuvidaPPE').click(function(){
    $('#descricaoPPE').show();
    $('#dadosProfissao').hide();
});
$('#btnDuvidaFATCA').click(function(){
    $('#descricaoFATCA').show();
    $('#dadosProfissao').hide();
});
$('#btnFecharPPE').click(function () {
    $('#descricaoPPE').hide();
    $('#dadosProfissao').show();
});
$('#btnFecharFATCA').click(function () {
    $('#descricaoFATCA').hide();
    $('#dadosProfissao').show();
});
    


function geraCampoUploadDoc() {
    // $('#tipoDocumento').val(docSelecionado);
    // btnClassUploadName = 'btn' + docSelecionado;
    // createUploader(btnClassUploadName);
}

$(document).ready(function () {

    // Banner In Out::::::::::::::::::
    setTimeout(function () {
        $('#bannerInOut').slideDown();
    }, 500)

    //$(window).resize(function () {
    //    resizeImg();
    //});

    $('#bannerInOut .close-banner').click(function () {
        $('#bannerInOut').slideUp();
    });
    $('#bannerFixo .close-banner').click(function () {
        $('#bannerFixo').slideUp();
    });
    // Fim Banner In Out::::::::::::::::::

    // Alterna accordion Rendimentos
    $(".box-rendimentos .pergunta label input").each(function() {
      if($(this).is(':checked')){
        $(this).parent('label').addClass('active');
      }
    });
    $('.box-rendimentos .pergunta label input').change(function(){
        var nameLabel = $(this).attr('name');
        $("input[name='"+nameLabel+"']").parent('label').removeClass('active');
        $(this).parent('label').addClass('active');
    })


  if($('#fichaPorEmail').is(":checked")){
    $('#accordionCONTRATO .btnCONTRATO, .pref-fpe').hide();
    $('.msg-sucesso-fpe').show();
    $('#nextController').val('CadastroFinalizado/Parcial');
  }
  // Simulação Benefício Crédito
  $('#btnSimularCreditoBeneficio').click(function(e){
    e.preventDefault();
    valorInvestimento = $('#inputValorInvestimento').val().replace(/[.,]/g,'');
    resultadoCredito = Math.round((valorInvestimento*50)/100);
    $('.resultado-simulador-beneficio-credito h3, .resultado-simulador-beneficio-credito h2').html('');
    $('.resultado-simulador-beneficio-credito h3').append('R$<strong id="valorInvestimentoBeneficio">'+valorInvestimento+'</strong>');
    $('.resultado-simulador-beneficio-credito h2').append('R$<strong id="valorCreditoBeneficio">'+resultadoCredito+'</strong><span>em crédito*</span>');
    $('#valorInvestimentoBeneficio,#valorCreditoBeneficio').mask('000.000.000.000.000,00', {reverse: true});

    if(valorInvestimento == ""){
      $('#errorMSGinputValorInvestimento').show().text('Insira um valor de investimento');
    }else if(valorInvestimento < 100000){
      $('#errorMSGinputValorInvestimento').show().text('O investimento mínimo é de R$1.000,00');
    }else{
      $('#errorMSGinputValorInvestimento').hide().text('');
      $('.simulador-beneficio-credito .box-simulador-beneficio-credito').hide();
      $('.simulador-beneficio-credito .resultado-simulador-beneficio-credito').fadeIn();
    }
  })

  $('#btnNovaSimulacaoBeneficio').click(function(){
    $('#errorMSGinputValorInvestimento').hide().text('');
    $('.simulador-beneficio-credito .box-simulador-beneficio-credito').fadeIn();
    $('.simulador-beneficio-credito .resultado-simulador-beneficio-credito').hide();
  })

  $('#inputValorInvestimento').mask('000.000.000.000.000,00', {reverse: true});


})
