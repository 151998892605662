$(document).ready(function(){

	// Barra Institucional:::::::::::::::::::::::::::::::::::::::::::::::::::::::
	$('#barra-institucional .links-institucionais-mob').click(function(){
		classeToggle = $(this).attr('classe-base');
		if(classeToggle == ""){
			classeToggle = 'xs';
		}
		$('#barra-institucional .links-institucionais ul').toggleClass('links-hover-'+classeToggle);
	});
	// Com acesso Dayconnect
	$('#menuDayconnect .link-dayconnect-mob').click(function(){
		$('#menuDayconnect').toggleClass('open');
	});
	// Fim Barra Institucional:::::::::::::::::::::::::::::::::::::::::::::::::::

	// Menu Principal :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	$('header .btn-menu-mob').click(function(e){
		$('header').toggleClass('menu-mob-left');
		$('body, html').toggleClass('hide-scroll');
		$('header .btn-menu-mob i').toggleClass("fa-bars fa-times");
		$('header .btn-menu-mob i').toggleClass("fa-bars fa-times");
    	e.preventDefault();
	});
	// Fim Menu Principal :::::::::::::::::::::::::::::::::::::::::::::::::::::::

    // Forms *padrao* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	$("label input, label select, label textarea").each(function () {
	    if ($(this).val() != "") {
	        $(this).parent('label').addClass('label-embutido');
	    }
	    if ($(this).is(':disabled')) {
	        $(this).parent('label').addClass('label-disabled');
	    }
	});
	$('label input, label select, label textarea').focus(function(){
		$(this).parent('label').addClass('label-embutido');
	}).focusout(function(){
		if($(this).val() == ""){
			$(this).parent('label').removeClass('label-embutido');
		}
		}
	})
	//Toggle Select - *padrao*
	$(".toggle-select input").each(function() {
		if($(this).is(':checked')){
			$(this).parent('.toggle-select').children('i').addClass('fa-toggle-on');
		}
		}
	});
	$(".toggle-select input").change(function(){
		getName = $(this).attr('name');
		$('.toggle-select input[type=radio][name='+getName+']').parent('.toggle-select').children('i').removeClass('fa-toggle-on');
		$(this).parent('.toggle-select').children('i').addClass('fa-toggle-on');
		if($(this).is(':checked')){
			$('.toggle-select input[type=checkbox][name='+getName+']').parent('.toggle-select').children('i').addClass('fa-toggle-on');
		}else{
			$('.toggle-select input[type=checkbox][name='+getName+']').parent('.toggle-select').children('i').removeClass('fa-toggle-on');
		}
	})
	// Fim Forms ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

		// Footer :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	$('#btnMenuFooterMob').click(function(e){
		$('#menuFooter').toggleClass('closed-sm');
		$('html, body').animate({scrollTop:$('footer .barra-footer').position().top - 60}, 'slow');
		if($('#mapaSiteFooter').is(':visible')){
			$('#mapaSiteFooter').hide();
		}
	});
	$('#menuFooter .btn-mapa-site').click(function(e){
		$('#mapaSiteFooter').slideToggle(50);
		$('html, body').animate({scrollTop:$('#mapaSiteFooter').position().top - 100}, 'slow');
    	e.preventDefault();
	});
	// Fim Footer :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

	// Carrossel ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
		// Centraliza legenda verticalmente no banner
		// Centraliza legenda verticalmente no banner
		hightLegend = '-' + $('.swiper-container .full-container .swiper-legend').height() / 2+'px';
		$('.swiper-container .full-container .swiper-legend').css('margin-top', hightLegend);
		// Centraliza bullets paginação  horizontalmente
		widthLegend = '-' + $('.swiper-container .swiper-pagination').height() / 2+'px';
		$('.swiper-container .swiper-pagination').css('margin-left', widthLegend);
	// Fim Carrossel ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::




	// Resize Tela Desktop ::::::::::::::::::::::::::::::::::::::::::::::::::::::
	$(window).resize(function(){
		$('#mapaSiteFooter').hide();
		if($(window).width() > 480){
			// $('#barra-institucional .links-institucionais ul').show();
		}else{
		}else{
			// $('#barra-institucional .links-institucionais ul').hide();
		}

    if($(window).width() > 768){
			$('body, html').removeClass('hide-scroll');
		}else{
		}else{
			if($('header').hasClass('menu-mob-left')){
				$('body, html').addClass('hide-scroll');
				$('body, html').addClass('hide-scroll');
			}
		}

    $('#btnMenuFooterMob i').removeClass("fa-times").addClass("fa-bars");
	});

	// Accordion *padrao* :::::::::::::::::::::::::::::::::::::::::::::::::::::
	$('.abrir-accordion').click(function() {
		targetAccordion = '#'+$(this).attr('target-accordion');
		$(targetAccordion).parent('.body-accordion').children('.content-accordion').removeClass('open');
		$(targetAccordion).addClass('open');
		if($(this).attr('active-tab')){
			activeClass = $(this).attr('active-tab');
			var allListElements = '.abrir-accordion.'+activeClass;
			$($(this).closest('.tabs-accordion')).find(allListElements).removeClass('active');
			$(this).addClass(activeClass);
		}
	})
	// Accordion *padrao - FAQ* :::::::::::::::::::::::::::::::::::::::::::::::::::::
	$('.accordion-panel .accordion .accordion-title').click(function() {
	$('.accordion-panel .accordion .accordion-title').click(function() {

		if($(this).parent().parent('.accordion-panel').hasClass('toggle-accordion')){
			$(this).parent().parent('.accordion-panel').children('.accordion').removeClass('open');
		}else{

		}

		if($(this).parent('.accordion').hasClass('open')){
			$(this).parent('.accordion').removeClass('open');
		}else{
			$(this).parent('.accordion').addClass('open');
		}
	})
	// Accordion filtros menu ::::::::::::::::
	$('#btnMenuFilterAccordionMob').click(function(e){
		e.preventDefault();
		$(this).parent('.accordion-menu-filters').children('ul').toggleClass('hidden-xs');
	})
	$('.accordion-menu-filters ul li a').click(function(){
		if($(this).parent().parent('ul').hasClass('hidden-xs') == false && $(window).width() < 769){
			$(this).parent().parent('ul').addClass('hidden-xs');
		}
	})

	// Modal :::::::::::::::::::::::::::::::
    $(document).on("click", ".abrirModalDaycoval", function () {
        targetModal = '#' + $(this).attr('target-modal');
        $('.modal-daycoval-lightbox').hide();
        $(targetModal).show();
    });

	$('.fecharModalDaycoval').click(function(){
		$('.modal-daycoval-lightbox').hide();
	})

    //Principais Indicadores
	alturasColunasTabelas();
	function alturasColunasTabelas() {
	    $('.tabela-ratings ul, .tabela-indicadores ul').each(function () {
	        var highestBox = 0;
	        $('li', this).each(function () {
	            if ($(this).height() > highestBox)
	                highestBox = $(this).height();
	        });
	        $('li', this).height(highestBox);
	        $('.tabela-ratings ul li:first-child').height('auto');
	        $('ul.cabecalho li:first-child').height(0);
	    });
	}

    $(document).ready(function () {
        if ($('#bannerInOut').children()[1] != undefined) {
            var bannerUrl = $('#bannerInOut').children()[1].href;
            if (bannerUrl.indexOf("#") >= 0) {
                $('#banner').css("cursor", "default");
                $('#linkBanner').removeAttr("href");
            }
        }
        }
    })

});





